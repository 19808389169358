const cloudinaryDomainSuffix = '.cloudinary.com';

export const getCloudinarySubDomain = () => {
  const hostname = window?.location?.hostname || '';
  return hostname.endsWith(cloudinaryDomainSuffix) ? hostname.split('.')[0] : null;
};

/**
 * returns the env name that comes after "-" including it's number (when present)
 * example:
 *  console-staging3 -> staging3
 *  app-staging3 -> staging3
 *  staging3 -> staging3
 * @param subDomain
 * @returns {*}
 */
export const getEnvNameAfterDash = (subDomain) => subDomain.match(/([^-]+)$/)?.[1];

const getEnvNameFromSubDomain = (subDomain) => {
  return subDomain.startsWith('console') ? subDomain : getEnvNameAfterDash(subDomain);
};

/**
 * figures out the environment based on the URL currently loaded in
 * examples:
 *  https://www.cloudinary.com/ -> undefined
 *  https://staging.cloudinary.com -> staging
 *  https://something-staging14.cloudinary.com -> staging14
 *  https://kuku.cloudinary.com -> kuku
 *  https://console-staging2.cloudinary.com -> console-staging2
 *  https://console.cloudinary.com -> console
 */
const getEnvFromUrl = () => {
  const subDomain = getCloudinarySubDomain();

  if (subDomain) {
    return getEnvNameFromSubDomain(subDomain);
  }
};

export default getEnvFromUrl;
